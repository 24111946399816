var scroll = 0;
var primary_navbar = jQuery('#primary-navbar');

jQuery(window).scroll(function () {
    scroll = jQuery(window).scrollTop();
    if (scroll > 200) {
        primary_navbar.addClass('shadow bg-van-ooijen-green-dark');
    } else {
        primary_navbar.removeClass('shadow bg-van-ooijen-green-dark');
    }
});

var menu_open = false;
var menu = jQuery('#menu-bar');
var menu_button = jQuery('#menu-button');

function toggleMenu() {
    menu_open = !menu_open;
    if (menu_open) {
        menu_button.addClass('is-active');
        menu.addClass('is-active');
    } else {
        menu_button.removeClass('is-active');
        menu.removeClass('is-active');
    }
}

jQuery(document).ready(function ($) {
    $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        items: 1
    })
});
